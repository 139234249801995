// @flow
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import axiosconfig from "../../../config/axios-config";
import { Wrapper } from "@googlemaps/react-wrapper";
import classes from "../../../styles/agent.module.css";
import PlacesAutocomplete from "../../Google/Places";
import nextConfig from "../../../next.config";
import ReactPaginate from "react-paginate";
import { Fragment } from "react";
import { EmptyDataBlock } from "../../utility/EmptyDataBlock";
//Latitude 40.997830
//Longitude -74.347610

export function FindAgentFn() {
  const headers = {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  };
  const [coords, setCoords] = React.useState({
    lat: 0,
    lng: 0,
    range: 10,
    flag: true,
  });
  const ref = useRef(null);
  const [map, setMap] = useState();
  const [locMarker, setLocMarker] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [coordsData, setCoordsData] = useState();
  const [searchData, setSearchData] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({
    lat: "",
    lng: "",
  });
  const [isAgentsLoading, setAgentsLoadingState] = useState(false);
  const [contactus, getContactus] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [travelAgencies, setTravelAgencies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    contactUsFn();
  }, []);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current, {
        center: {
          lat: coords.lat,
          lng: coords.lng,
        },
        zoom:
          !coords.range || coords.range == 1000 || 0 || null || undefined
            ? 15
            : 12,
      })
    );
  }, [travelAgencies, coords]);

  useEffect(() => {
    setLocMarker([
      new google.maps.Marker({
        position: { lat: coords.lat, lng: coords.lng },
        map: map,
        title: searchData?.data?.results[0].name
          ? searchData.data.results[0].name
          : "your Location",
      }),
    ]);

    let arr = [];
    let latlng = [];
    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    if (coordsData?.length) {
      travelAgencies?.map((coord, i) => {
        arr.push(
          new google.maps.Marker({
            position: {
              lat: coord.locationInformation[0].tnAddress.lat,
              lng: coord.locationInformation[0].tnAddress.lng,
            },
            title: coord.name,
            clickable: true,
            map: map,
            label: {
              text: `${i + 1 + 5 * (currentPage - 1)}`,
              color: "white",
              fontSize: "12px",
              fontWeight: "bold",
            },
          })
        );
        latlng.push(
          new google.maps.LatLng(
            coord.locationInformation[0].tnAddress.lat,
            coord.locationInformation[0].tnAddress.lng
          )
        );
        arr[i].addListener("click", () => {
          infoWindow.setContent(
            `<p style='margin-bottom:0;padding:10px;'>${
              coord.name
            } <span style='display:block;'>${
              coord?.locationInformation[0]?.tnAddress?.full_Address
            }</span><span style='display:block;'>Phone : ${
              coord.locationInformation[0]?.phone1 ||
              coord.locationInformation[0]?.phone2
            }</span></p>`
          );
          infoWindow.open(map, arr[i]);
        });
        setMarkers(arr);
      });
      let totelLan = [];
      totelLan.push(...latlng, new google.maps.LatLng(coords.lat, coords.lng));
      let latlngbounds = new google.maps.LatLngBounds();
      for (var i = 0; i < totelLan.length; i++) {
        latlngbounds.extend(totelLan[i]);
      }
      map.fitBounds(latlngbounds);
      map.panToBounds(latlngbounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    finishRequest();
  }, [coordsData]);

  async function contactUsFn() {
    const res = await axiosconfig.get("website/contactus");

    const alldata = await res.data;
    getContactus(alldata.data[0]);
  }

  const getLocation = async () => {
    if (navigator.geolocation) {
      const position = await navigator.geolocation.getCurrentPosition(
        async (position) => {
          setCoords({
            ...coords,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setAgentsLoadingState(true);
          const pageFeaturesRes = await axiosconfig
            .get(`crm/travelagency/in_range?Range=${10}`, {
              params: {
                UserFrontendGeoInfo: true,
                lat: position.coords.latitude,
                lang: position.coords.longitude,
              },
            })
            .catch(() => setAgentsLoadingState(false));
          setCoordsData(pageFeaturesRes?.data?.data?.travelAgencies);
          setAgentsLoadingState(false);
        }
      );
    }
  };

  async function data() {
    setAgentsLoadingState(true);
    window.scrollTo(0, 400);
    const position = await navigator.geolocation.getCurrentPosition(
      async (position) => {
        setCoords({
          ...coords,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setAgentsLoadingState(true);
        const pageFeaturesRes = await axiosconfig
          .get(`crm/travelagency/in_range?Range=${Number(coords.range)}`, {
            params: {
              UserFrontendGeoInfo: true,
              lat: position.coords.latitude,
              lang: position.coords.longitude,
              // lat: 37.5385087,
              // lang: -77.43428,
            },
          })
          .catch(() => setAgentsLoadingState(false));
        setCoordsData(pageFeaturesRes?.data?.data?.travelAgencies);
        setAgentsLoadingState(false);
      }
    );
  }

  async function searchDataFn() {
    if (selectedPlace.lat && selectedPlace.lng) {
      setAgentsLoadingState(true);
      window.scrollTo(0, 400);
      setCoords({
        ...coords,
        lat: selectedPlace.lat,
        lng: selectedPlace.lng,
      });
      const pageFeaturesRes = await axiosconfig
        .get(
          `crm/travelagency/in_range?Range=${coords.range ? coords.range : 10}`,
          {
            params: {
              UserFrontendGeoInfo: true,
              lat: selectedPlace.lat,
              lang: selectedPlace.lng,
            },
          }
        )
        .catch(() => setAgentsLoadingState(false));
      setCoordsData(pageFeaturesRes?.data?.data?.travelAgencies);
      setAgentsLoadingState(false);
    }
  }

  const setSelectedCoordinates = async (coords) => {
    setSelectedPlace({
      lat: coords.lat,
      lng: coords.lng,
    });
    searchDataFn();
  };

  const setAddress = async () => {};

  function finishRequest() {
    setTotalPages(Math.ceil(coordsData?.length / 5));
    handlePagination();
    setAgentsLoadingState(false);
  }

  const handlePagination = (event) => {
    const startIndex = event ? parseInt(event.selected * 5) : 0;
    const endIndex = event ? parseInt(event.selected * 5 + 5) : 5;
    setCurrentPage(event ? parseInt(event.selected + 1) : 1);
    setTravelAgencies(
      coordsData?.length ? coordsData.slice(startIndex, endIndex) : []
    );
  };

  return (
    <div className="agents-section blog-archive px-xl-0 px-4 py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 pr-lg-5">
            <div className="map-wrapper">
              <div
                style={{ width: "100%", height: "50vh" }}
                id="map"
                ref={ref}
              ></div>
            </div>
            <div className="form-wrapper mt-lg-5 mt-4 py-4">
              <form action="" className="distance-form">
                <div className="form-label">
                  <h4>You Are Near : </h4>
                </div>
                <div className="row">
                  <div className="col-lg-9 col-sm-8 mb-sm-0 mb-2">
                    <select
                      onChange={(event) => {
                        setCoords({ ...coords, range: event.target.value });
                      }}
                      className="form-control form-select mile-select"
                      id="distance"
                    >
                      <option value={10}>10 mi</option>
                      <option value={20}>20 mi</option>
                      <option value={25}>25 mi</option>
                      <option value={30}>30 mi</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-sm-4">
                    <button
                      type="button"
                      className="search-btn"
                      onClick={() => {
                        data();
                      }}
                    >
                      <i className="fa fa-search mr-1"></i>
                      Refine Search
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="form-wrapper">
              <form action="" className="search-form">
                <div className="form-label">
                  <h5>Or search for an Agent near to this address </h5>
                </div>
                <div className="row">
                  <div className="col-lg-9 col-sm-8 mb-sm-0 mb-2">
                    <div className="places-container">
                      <PlacesAutocomplete
                        handleCoordsChange={setSelectedCoordinates}
                        handlePlaceChange={setAddress}
                        coordinates="ahmed"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-4">
                    <button
                      type="button"
                      className="search-address-btn"
                      onClick={() => searchDataFn()}
                      disabled={!selectedPlace.lat || !selectedPlace.lng}
                    >
                      <i className="fa fa-search mr-1"></i>
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="text-wrapper sticky-agent mt-4">
              <h4 className="mb-1">
                <span className="fw-bold text-danger">Or Contact</span>
                <span className="display-5 font-weight-bold ml-1">
                  Central Holidays
                </span>
              </h4>

              <p className="mb-4">{contactus.fullAddress}</p>

              <div className="times">
                {contactus.officeHours?.map((office, index) => {
                  return (
                    <>
                      <p key={index} className="mb-1">
                        <i className="far fa-clock mr-2"></i>
                        {office}
                      </p>
                    </>
                  );
                })}
                <br />
                <p className="mb-1">
                  <span className="text-danger">
                    <i className="fa fa-phone mr-2"></i>
                    Main Phone
                  </span>{" "}
                  :{contactus.mainPhone}
                </p>
                <p className="mb-1">
                  <span className="text-danger">
                    <i className="fa fa-phone mr-2"></i> Local Phone
                  </span>{" "}
                  :{contactus.localPhone}
                </p>
                <p className="mb-1">
                  <span className="text-danger">
                    <i className="fa fa-fax mr-2"></i> Fax
                  </span>{" "}
                  : {contactus.fax}
                </p>
                <p className="mb-1">
                  <span className="text-danger">
                    <i className="far fa-envelope mr-2"></i> Email
                  </span>{" "}
                  :{contactus.email1}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 secondary">
            <div className="sidebar pl-xl-3">
              <aside className="widget widget_latest_post widget-post-thumb">
                <h3 className="widget-title text-left border-0 w-100 px-0 text-danger">
                  Near Agents
                  <p className="mb-0 mt-2 fs-12 text-muted text-capitalize fw-500">
                    List of travel agencies near your location.
                  </p>
                </h3>
                <div className={classes.agents_wrapper}>
                  {!isAgentsLoading ? (
                    travelAgencies?.length ? (
                      <Fragment>
                        <ul className="mx-0">
                          {travelAgencies.map((data, i) => {
                            return (
                              <>
                                <li key={data.id} className="agent-link">
                                  <figure className="post-thumb">
                                    {data.logo ? (
                                      <img
                                        src={data.logo}
                                        alt="Central Holidays"
                                      />
                                    ) : (
                                      <img
                                        src="/map-placeholder.jpg"
                                        alt="Central Holidays"
                                      />
                                    )}
                                  </figure>
                                  <div className="post-content d-flex">
                                    <span
                                      className="badge badge-danger mx-1"
                                      style={{ maxHeight: 20 }}
                                    >
                                      {i + 1 + 5 * (currentPage - 1)}
                                    </span>
                                    <div className="wrap ">
                                      <h5 className="mb-0">
                                        <p
                                          className="font-weight-bold mb-0"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {data.name}
                                        </p>
                                      </h5>
                                      <div className="entry-meta">
                                        <div className="posted-on">
                                          <div className="pl-2">
                                            <span className="fs-13">
                                              {
                                                data.locationInformation[0]
                                                  ?.tnAddress.streetNumber
                                              }{" "}
                                              {
                                                data.locationInformation[0]
                                                  ?.tnAddress.streetName
                                              }
                                            </span>
                                            <br></br>
                                            <span className="fs-13">
                                              {
                                                data.locationInformation[0]
                                                  ?.tnAddress.city
                                              }
                                              ,{" "}
                                              {
                                                data.locationInformation[0]
                                                  ?.tnAddress.state
                                              }{" "}
                                              {
                                                data.locationInformation[0]
                                                  ?.tnAddress.zip
                                              }
                                            </span>
                                            <br />
                                            <span className="d-block mt-2 fs-13">
                                              <i className="fa fa-phone mr-2"></i>{" "}
                                              {
                                                data.locationInformation[0]
                                                  ?.phone1
                                              }{" "}
                                              {
                                                data.locationInformation[0]
                                                  ?.phone2
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          breakLabel={"..."}
                          pageCount={totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={5}
                          onPageChange={handlePagination}
                          containerClassName={"pagination"}
                          pageLinkClassName={"click-page"}
                          previousClassName={"click-page"}
                          nextClassName={"click-page"}
                          activeClassName={"active"}
                        />
                        <span className="d-block text-center fs-12 text-muted mt-3">
                          Total : {coordsData?.length} Results
                        </span>
                      </Fragment>
                    ) : (
                      <EmptyDataBlock text="No Agents Near Your Location"></EmptyDataBlock>
                    )
                  ) : (
                    <div className={classes.agents_loader}>
                      <span className="css-loader"></span>
                    </div>
                  )}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FindAgent() {
  return (
    <Wrapper apiKey={nextConfig.googleAPIKey} libraries={["places"]}>
      <FindAgentFn />
    </Wrapper>
  );
}

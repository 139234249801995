import Image from "next/image";
import logoCH1 from "../../assets/images/logoCH1.png";
export function EmptyDataBlock(props) {
  return (
    <div className="empty-card-container w-100">
      <Image
        width={230}
        height={70}
        loading="lazy"
        alt="Central Holidays"
        src={logoCH1}
      />
      <div className="text fw-600 mt-2 text-center">{props.text}</div>
    </div>
  );
}

import * as React from "react";

export default function Brochures({ brochure }) {
  return (
    <div className={`col-md-6 col-lg-4 order-${brochure.order}`}>
      <div className="special-item">
        <figure className="special-img">
          <a href={brochure?.pdfUrl} target="_blank" rel="noreferrer">
            <img src={brochure.photoURL} alt={brochure.title} />
          </a>
        </figure>

        <div className="special-content">
          <div className="package-price">
            <h4>{brochure.title}</h4>
            <p
              className="fs-14"
              dangerouslySetInnerHTML={{ __html: brochure?.description }}
            ></p>
            {brochure?.pdfUrl.length > 0 && (
              <a
                href={brochure?.pdfUrl}
                target="_blank"
                rel="noreferrer"
                className="button-primary"
              >
                {" "}
                Download
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @next/next/no-img-element */
import axiosconfig from "../../../config/axios-config";
import Image from "next/image";
import classes from "../../../styles/contact.module.css";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import Cookies from "js-cookie";

export function ContactFn({ contactData }) {
  const ref = useRef(null);
  const [map, setMap] = useState();
  const [locMarker, setLocMarker] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [coords, setCoords] = React.useState({
    lat: Number(contactData.latitude),
    lng: Number(contactData.longitude),
  });

  useEffect(() => {
    setMap(
      new window.google.maps.Map(ref.current, {
        center: {
          lat: coords.lat,
          lng: coords.lng,
        },
        zoom: 17,
      })
    );
  }, [coords]);

  useEffect(() => {
    setLocMarker([
      new google.maps.Marker({
        position: { lat: coords.lat, lng: coords.lng },
        map: map,
      }),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    iamRobot: "",
  });
  const [userDataTouchState, setUserDataTouchState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    message: false,
  });
  const [socialMedia, getsocialMedia] = useState([]);
  const [sales, getSales] = useState([]);
  const [postState, handlePostState] = useState({
    inProgress: false,
    state: "",
  });
  const [UTMCodesObject, setUTMCodesObject] = useState({});
  const [referrerUrl, setReferrerUrl] = useState("");
  useEffect(() => {
    socialMediaFn();
    salesFn();
    if (Cookies.get("myCookieUtms")) {
      const UTMCodes = Cookies.get("myCookieUtms");
      const UTMCodesParse = JSON.parse(UTMCodes);
      setUTMCodesObject(UTMCodesParse);
    }
    if (Cookies.get("myCookieReferrer")) {
      setReferrerUrl(Cookies.get("myCookieReferrer"));
    }
  }, []);

  async function socialMediaFn() {
    const res = await axiosconfig.get("website/socialmedialink");

    const alldata = await res.data;

    getsocialMedia(alldata.data);
  }

  async function salesFn() {
    const res = await axiosconfig.get("admin/user/getallusersbycompanyid", {
      params: {
        PageSize: 500,
      },
    });

    const alldata = await res.data;
    getSales(alldata.data);
  }
  var salesTeam = [];
  sales.forEach((sale) => {
    if (sale.isOutDoorSalesAgent == true) {
      salesTeam.push(sale);
    }
  });

  const changeData = (e) => {
    if (e.target.name === "firstName") {
      setUserData({
        ...userData,
        firstName: e.target.value,
      });
    } else if (e.target.name === "lastName") {
      setUserData({
        ...userData,
        lastName: e.target.value,
      });
    } else if (e.target.name === "phone") {
      setUserData({
        ...userData,
        phone: e.target.value,
      });
    } else if (e.target.name === "email") {
      setUserData({
        ...userData,
        email: e.target.value,
      });
    } else if (e.target.name === "message") {
      setUserData({
        ...userData,
        message: e.target.value,
      });
    } else if (e.target.name === "email_address") {
      setUserData({
        ...userData,
        iamRobot: e.target.value,
      });
    }
  };
  const { firstName, lastName, message, phone, email, iamRobot } = userData;

  const handleUser = (e) => {
    e.preventDefault();
    handlePostState({
      inProgress: true,
      state: "",
    });
    return axiosconfig
      .post("website/contactformsubmitted", {
        companyId: "61d58047cd155a856c9d4848",
        firstName,
        lastName,
        phone,
        email,
        message,
        isRead: false,
        utmCodes: UTMCodesObject,
        referralUrl: referrerUrl,
        iamRobot,
      })
      .then(() => {
        handlePostState({
          inProgress: false,
          state: "yes",
        });
        setUserData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
        setUserDataTouchState({
          firstName: false,
          lastName: false,
          email: false,
          phone: false,
          message: false,
        });
        setTimeout(() => {
          handlePostState({
            inProgress: false,
            state: "",
          });
        }, 4000);
      })
      .catch(() => {
        handlePostState({
          inProgress: false,
          state: "no",
        });
      });
  };

  return (
    <>
      <div
        className="contact-page-section px-xl-0 px-md-4 px-3"
        Style="margin-top:85px"
      >
        <div className="contact-form-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mb-lg-0 mb-5">
                <div className="contact-from-wrap">
                  <div
                    className={`contact-result-message-container ${
                      postState.state === "yes" ? "shown" : ""
                    }`}
                  >
                    <div className="contact-result-message success text-center">
                      <div className="icon mb-4">
                        <i className="fa fa-check-circle"></i>
                        <i className="fa fa-times-circle"></i>
                      </div>
                      <p className="text">
                        {contactData.successMessage
                          ? contactData.successMessage
                          : "Message Sent Successfully , We will contact you as soon as possible."}
                      </p>
                    </div>
                  </div>

                  <div
                    className={`contact-result-message-container ${
                      postState.state === "no" ? "shown" : ""
                    }`}
                  >
                    <div className="contact-result-message failure text-center">
                      <div className="icon mb-4">
                        <i className="fa fa-check-circle"></i>
                        <i className="fa fa-times-circle"></i>
                      </div>
                      <p className="text">
                        {contactData.failureMessage
                          ? contactData.failureMessage
                          : "Oops! Something went wrong , please try again later."}
                      </p>
                    </div>
                  </div>

                  <form
                    onSubmit={handleUser}
                    className="contact-from contactus-form"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="thm-input-wrapper">
                            <i className="icon fa fa-user text-danger"></i>
                            <input
                              value={userData.firstName}
                              onChange={(e) => {
                                setUserDataTouchState({
                                  firstName: true,
                                });
                                changeData(e);
                              }}
                              type="text"
                              id="firstName"
                              name="firstName"
                              className="form-control"
                              placeholder="Enter First Name"
                              autoComplete="off_name"
                            />
                          </div>
                          {userDataTouchState.firstName &&
                            !userData.firstName && (
                              <span className={classes.form_input_err}>
                                First Name is Required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="thm-input-wrapper">
                            <i className="icon fa fa-users text-danger"></i>
                            <input
                              value={userData.iamRobot}
                              onChange={(e) => {
                                changeData(e);
                              }}
                              type="email"
                              id="email_address"
                              name="email_address"
                              className="form-control"
                              placeholder="Enter Verify Email"
                              style={{
                                width: 0,
                                height: 0,
                                padding: 0,
                                margin: 0,
                              }}
                              autoComplete="off_name"
                            />
                            <input
                              value={userData.lastName}
                              onChange={(e) => {
                                setUserDataTouchState({
                                  firstName: true,
                                  lastName: true,
                                });
                                changeData(e);
                              }}
                              type="text"
                              id="lastName"
                              name="lastName"
                              className="form-control"
                              placeholder="Enter Last Name"
                              autoComplete="off_name"
                            />
                          </div>
                          {userDataTouchState.lastName &&
                            !userData.lastName && (
                              <span className={classes.form_input_err}>
                                Last Name is Required
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            Email Address
                            <span className="text-danger">*</span>
                          </label>
                          <div className="thm-input-wrapper">
                            <i className="icon fa fa-envelope text-danger"></i>
                            <input
                              value={userData.email}
                              onChange={(e) => {
                                setUserDataTouchState({
                                  firstName: true,
                                  lastName: true,
                                  email: true,
                                });
                                changeData(e);
                              }}
                              type="text"
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter Email Address"
                              autoComplete="off"
                            />
                          </div>
                          {userDataTouchState.email && !userData.email && (
                            <span className={classes.form_input_err}>
                              Email Address is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="phone" className="form-label">
                            Phone Number
                            <span className="text-danger">*</span>
                          </label>
                          <div className="thm-input-wrapper">
                            <i className="icon fa fa-phone text-danger"></i>
                            <input
                              value={userData.phone}
                              onChange={(e) => {
                                setUserDataTouchState({
                                  firstName: true,
                                  lastName: true,
                                  email: true,
                                  phone: true,
                                });
                                changeData(e);
                              }}
                              type="text"
                              id="phone"
                              name="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              autoComplete="off_name"
                            />
                          </div>
                          {userDataTouchState.phone && !userData.phone && (
                            <span className={classes.form_input_err}>
                              Phone Number is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="message" className="form-label">
                            Message
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            value={userData.message}
                            onChange={(e) => {
                              setUserDataTouchState({
                                firstName: true,
                                lastName: true,
                                email: true,
                                phone: true,
                                message: true,
                              });
                              changeData(e);
                            }}
                            className="form-control"
                            name="message"
                            id="message"
                            rows="5"
                            placeholder="Enter your message"
                            Style="font-size: 0.9rem"
                            autoComplete="off_name"
                          ></textarea>
                          {userDataTouchState.message && !userData.message && (
                            <span className={classes.form_input_err}>
                              Message is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <button
                          type="submit"
                          className="main-action-btn py-3 d-flex justify-content-center align-items-center"
                          id="sendMessageButton"
                          Style="max-width: 200px;vertical-align: middle"
                          disabled={
                            postState.inProgress ||
                            !firstName ||
                            !lastName ||
                            !email ||
                            !phone ||
                            !message
                          }
                        >
                          <span className="mr-3">Send Message</span>
                          {postState.inProgress && (
                            <Image
                              loading="lazy"
                              src="/loader-w.gif"
                              alt="Loading Message"
                              width={22}
                              height={22}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-detail-wrap">
                  <div className="details-list">
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="fas fa-map-signs"></i>
                        </span>
                        <div className="details-content">
                          <h4>Location Address</h4>
                          <span
                            className="d-inline-block"
                            Style="max-width: 350px"
                          >
                            {contactData.fullAddress}
                          </span>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="fas fa-envelope-open-text"></i>
                        </span>
                        <div className="details-content">
                          <h4>Email Address</h4>
                          <span>{contactData.email1}</span>
                          <br />
                          <span>{contactData.email2}</span>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="fas fa-phone-volume"></i>
                        </span>
                        <div className="details-content">
                          <h4>Phone Number</h4>
                          <p className="mb-0">
                            <span className="mb-0 text-danger">
                              Main Phone:{" "}
                            </span>
                            <a href="mailTo:" className="text-dark">
                              <span>{contactData.mainPhone}</span>
                            </a>
                          </p>
                          <p className="mb-0">
                            <span className="mb-0 text-danger">
                              Local Phone:
                            </span>
                            <a href="mailTo:" className="text-dark">
                              <span>{contactData.localPhone}</span>
                            </a>
                          </p>
                          <p className="mb-0">
                            <span className="mb-0 text-danger">Fax: </span>
                            <a href="#:" className="text-dark">
                              <span>{contactData.fax}</span>
                            </a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="fas fa-clock"></i>
                        </span>
                        <div className="details-content">
                          <h4>Working Hours</h4>
                          <div className="times">
                            {contactData.officeHours.map((office) => {
                              return (
                                <>
                                  <p className="mb-0">{office}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {salesTeam.length > 0 && (
          <div className="sales-section mb-5 pb-lg-5">
            <div className="container">
              <h2 className="py-2 text-center" Style="margin-bottom:70px">
                Our Sales Team
              </h2>
              <div className="row d-flex">
                {sales.map((sales) => {
                  return (
                    <>
                      {sales.isOutDoorSalesAgent && (
                        <>
                          <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
                            <div className="sales-member">
                              <div className="image">
                                <img
                                  className="sales-img"
                                  src={
                                    sales.photo ? sales.photo : "/profile.png"
                                  }
                                  alt={sales.firstName}
                                  width={100}
                                  height={100}
                                />
                              </div>
                              <div className="text">
                                <h5 className="name">
                                  <strong>
                                    {sales.firstName} {sales.lastName}
                                  </strong>
                                </h5>
                                <ul className="details">
                                  {sales.title !== null && (
                                    <li className="title">
                                      <span>{sales.title}</span>
                                    </li>
                                  )}
                                  {sales.businessPhone && (
                                    <li className="phone">
                                      <span className="text-dark">
                                        {sales.businessPhone}{" "}
                                        {sales.extension
                                          ? `ext. ${sales.extension}`
                                          : ""}
                                      </span>
                                    </li>
                                  )}

                                  <li className="email">
                                    <a
                                      className="email text-dark"
                                      href={"mailto:" + sales.email}
                                    >
                                      {sales.email}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="map-wrapper">
        <div
          style={{ width: "100%", height: "400px" }}
          id="map"
          ref={ref}
        ></div>
      </div>
    </>
  );
}

export default function Contact(props) {
  return (
    <Wrapper
      apiKey={"AIzaSyAicsoGlftseqfMAAftBsxeMTnNc9PaEvM"}
      libraries={["places"]}
    >
      <ContactFn contactData={props.contact.data[0]} />
    </Wrapper>
  );
}

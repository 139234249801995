export default function Request() {
  return (
    <>
      <section className="quote_section">
        <div className="container">
          <form action="" className="quote_form">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <label htmlFor="agencyName">
                    Agency Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-building"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="agancyName"
                      id="agencyName"
                      placeholder="Enter Agency Name .."
                      autoComplete="off1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <label htmlFor="consortia">
                    Consortia
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-globe-europe"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="consortia"
                      id="consortia"
                      placeholder="Enter consortia .."
                      autoComplete="off3"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="iata">
                    IATA/CLIA
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-paperclip"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="iata"
                      id="iata"
                      placeholder="Enter Consortia IATA/CLIA .."
                      autoComplete="off2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label htmlFor="fname">
                    First Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-user"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="fname"
                      id="fname"
                      placeholder="Enter First Name .."
                      autoComplete="off2"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label htmlFor="lname">
                    Last Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-users"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="lname"
                      id="lname"
                      placeholder="Enter Last Name .."
                      autoComplete="off5"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label htmlFor="phone">
                    Phone No.
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-phone-alt"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Enter Phone Number .."
                      autoComplete="off7"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-envelope"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Enter Email Address .."
                      autoComplete="off6"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-7">
                <div className="form-group">
                  <label htmlFor="address">
                    Address
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-map-marker-alt"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      id="address"
                      placeholder="Enter Address .."
                      autoComplete="off8"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5">
                <div className="form-group">
                  <label htmFor="stNo">
                    Street No.
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-map-marker-alt"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="stNo"
                      id="stNo"
                      placeholder="Enter Street No .."
                      autoComplete="off9"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6">
                <div className="form-group">
                  <label htmlFor="stName">
                    Street Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-street-view"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="stName"
                      id="stName"
                      placeholder="St. Name .."
                      autoComplete="off9"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6">
                <div className="form-group">
                  <label>
                    City
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-city"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      id="city"
                      placeholder="Enter City .."
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6">
                <div className="form-group">
                  <label>
                    State
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-cloud-moon"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      id="state"
                      placeholder="Enter State .."
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6">
                <div className="form-group">
                  <label>
                    Zip Code
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-map-pin"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="zcode"
                      id="zcode"
                      placeholder="Enter Zip Code .."
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="form-group">
                  <label>
                    Apt,floor,suit,etc..
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-map-signs"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="apt"
                      id="apt"
                      placeholder="Apt, floor , suit , etc."
                    />
                  </div>
                </div>
              </div>
              <div className="head col-12 my-3">
                <h4>
                  In Order To Provide You With An Accurate Quote, All The Trip
                  Information Below Is .
                </h4>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label> Destination </label>
                  <div className="input_wrapper">
                    <select
                      name="destination"
                      id="destination"
                      className="form-control form-select"
                    >
                      <option value="">-- Select Destination --</option>
                      <option value="Egypt">Egypt</option>
                      <option value="Greece">Greece</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6">
                <div className="form-group">
                  <label Style="visibility: hidden">Flight</label>
                  <div className="input-wrapper mt-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="isFlight"
                        name="isFlight"
                      />
                      <label className="form-check-label">
                        Do you need a flight
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div className="form-group">
                  <label Style="visibility: hidden">Departure City</label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-city"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="depCity"
                      id="depCity"
                      placeholder="Departure City"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="row no-gutters">
                  <div className="col-12">
                    <label>Departure Date</label>
                  </div>
                  <div className="col-4 pr-1">
                    <div className="input_wrapper">
                      <select
                        name="dayNum"
                        id="dayNum"
                        className="form-control form-select date"
                      >
                        <option value="">Day</option>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-4 pr-1">
                    <div className="input_wrapper">
                      <select
                        name="month"
                        id="month"
                        className="form-control form-select date"
                      >
                        <option value="">Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Angust</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input_wrapper">
                      <select
                        name="year"
                        id="year"
                        className="form-control form-select date"
                      >
                        <option value="">Year</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label> # Of Travelers </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-users"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="trNum"
                      id="trNum"
                      placeholder="No. of travelers"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label> # Of Nights </label>
                  <div className="input_wrapper">
                    <i className="icon fa fa-building"></i>
                    <input
                      type="text"
                      className="form-control"
                      name="nights"
                      id="nights"
                      placeholder="No. of Nights"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label> Accommodation </label>
                  <div className="input_wrapper">
                    <select
                      name="accom"
                      id="accom"
                      className="form-control form-select"
                    >
                      <option value="">Accommodations</option>
                      <option value="3">3 Stars</option>
                      <option value="4">4 Stars</option>
                      <option value="4+">4 Stars Superior</option>
                      <option value="5">5 Starts</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6">
                <div className="form-group">
                  <label> Budget/Person </label>
                  <div className="input_wrapper">
                    <select
                      name="budget"
                      id="budget"
                      className="form-control form-select"
                    >
                      <option value="">-- Budget --</option>
                      <option value="3">0 - 1000$</option>
                      <option value="4">1000$ - 2000$</option>
                      <option value="4">2000$ - 3000$</option>
                      <option value="4">3000$ - 4000$</option>
                      <option value="4">+4000$</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="input_wrapper">
                  <textarea
                    className="form-control"
                    name="comment"
                    id="comment"
                    rows="5"
                    placeholder="Question/Comment .."
                  ></textarea>
                </div>
              </div>
              <div className="col-12 text-right">
                <button className="action-btn">Submit Information</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

// @flow
import * as React from "react";
import Image from "next/image";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import axiosconfig from "../../../config/axios-config";

export default function Testimonial({ testimonial }) {
  const [test, getTest] = useState([...testimonial.data]);

  async function handlePage(page) {
    var pagenumber = page ? page.selected + 1 : 1;

    const alldata = await axiosconfig.get("website/testimonial", {
      params: {
        PageNumber: pagenumber,
        Status: "accepted",
        IsActive: true,
      },
    });
    const testData = await alldata.data;
    getTest(testData.data);
  }

  return (
    <div className="container my-5">
      {test.map((testimonial) => {
        return (
          <>
            {testimonial.isActive == true && (
              <div className="grid-item col-md-12 mb-2">
                <div className="testimonial-item">
                  <div className="quote mb-3">
                    <Image
                      layout=""
                      loading="lazy"
                      src="/left-quotes-sign.png"
                      width="50%"
                      height="50%"
                      alt=""
                    />
                  </div>

                  <div>
                    <p className="name text-main-dark">
                      {testimonial.titleMoreDescription}
                    </p>
                  </div>

                  <p
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: testimonial.description,
                    }}
                  ></p>

                  <div className="writer">
                    <div>
                      <p className="name font-weight-bold">
                        {testimonial.signature}
                      </p>
                      {/* <p className="position">CEO Director</p> */}
                    </div>
                  </div>
                  <div className="my-3 bg-main rounded-pill d-inline-block px-4 py-1">
                    <i className="far fa-calendar mr-2"></i>
                    <span>
                      {`${new Date(testimonial.createdDate).toLocaleString(
                        "en",
                        { day: "numeric" }
                      )}`}{" "}
                    </span>
                    <span>
                      {" "}
                      {`${new Date(testimonial.createdDate).toLocaleString(
                        "en",
                        { month: "long" }
                      )}`}{" "}
                    </span>
                    <span>{`${new Date(testimonial.createdDate).toLocaleString(
                      "en",
                      { year: "numeric" }
                    )}`}</span>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
      <br />
      <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={testimonial.totalPages}
        marginPagesDisplayed={3}
        pageRangeDisplayed={6}
        onPageChange={handlePage}
        containerClassName={"pagination"}
        pageLinkClassName={"click-page"}
        previousClassName={"click-page"}
        nextClassName={"click-page"}
        activeClassName={"active"}
      />
      <br />
    </div>
  );
}

import Link from "next/link";
import { EmptyDataBlock } from "../utility/EmptyDataBlock";

export default function Career(props) {
  return (
    <>
      <div className="vacancy-container career-page-section">
        <div className="row">
          <div className="col-12">
            <div className="vacancy-content-wrap">
              <div className="row">
                {props?.alldata?.data?.length ? (
                  <>
                    {props.alldata.data.map((career) => {
                      return (
                        <>
                          {career.isActive && (
                            <div
                              key={career.id}
                              className={`col-lg-4 col-md-6 order-${career.order}`}
                            >
                              <div className="vacancy-content">
                                <Link
                                  href={`/careers/${career.pageUrl}?pageContent=${props.pageUrlModule}`}
                                >
                                  <a className="stretched-link"></a>
                                </Link>
                                <h3 className="title">{career.title}</h3>
                                <h5 className="type">
                                  <i className="fa fa-briefcase mr-2"></i>
                                  Job Type : {career.quoteReference}
                                </h5>
                                <p className="status">
                                  <i className="far fa-keyboard mr-2"></i>
                                  Job Status :{" "}
                                  <span className="text-success">Open job</span>
                                </p>
                                <span className="btn bg-main hover-btn mt-3 fs-14 px-5 py-2">
                                  Read More
                                  <i className="fa fa-angle-double-right ml-2"></i>
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <EmptyDataBlock text="There is no currently active open jobs available."></EmptyDataBlock>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

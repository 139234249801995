import { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

function PlacesAutocomplete(props) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handlePlaceInput = (e) => {
    setValue(e.target.value);
  };

  const [selectedLatLng, setSelectedLatLng] = useState(null);

  const handlePlaceSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    getGeocode({ address: address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setSelectedLatLng({
        lat: lat,
        lng: lng,
      });
      if (props.index !== null && props.index !== undefined) {
        props.handleCoordsChange({ lat: lat, lng: lng }, props.index);
        props.handlePlaceChange({ ...results[0] }, props.index);
      } else {
        props.handleCoordsChange({ lat: lat, lng: lng });
        props.handlePlaceChange({ ...results[0] });
      }
    });
  };

  useEffect(() => {
    if (props?.value) setValue(props.value, false);
  }, [props.value]);

  return (
    <Combobox onSelect={handlePlaceSelect} aria-labelledby="demo">
      <ComboboxInput
        value={value}
        onChange={handlePlaceInput}
        disabled={!ready}
        placeholder="Search for an address ..."
        autoComplete="off_address"
      />
      {data?.length ? (
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      ) : (
        ""
      )}
    </Combobox>
  );
}

export default PlacesAutocomplete;

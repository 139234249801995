import Link from "next/link";

export default function Special(props) {
  return (
    <>
      <div className="archive-section blog-archive">
        <div className="archive-inner">
          <div className="container">
            <section className="central-section p-2">
              <div className="row wrapper mb-5">
                {props.specials.data.map((spec) => {
                  return (
                    <>
                      {spec.isActive == true && (
                        <>
                          <div
                            key={spec.id}
                            className={`offer-item mb-5 order-${spec.order}`}
                          >
                            <div className="row">
                              <div className="col-lg-5">
                                <figure className="image">
                                  <img src={spec.photo} alt="Offer" />
                                </figure>
                              </div>
                              <div className="col-lg-7">
                                <div className="content">
                                  <h3 className="title text-main">
                                    <strong> {spec.title} </strong>
                                  </h3>
                                  <h5>
                                    <strong>{spec.subTitle}</strong>
                                  </h5>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: spec.shortDescription,
                                    }}
                                    className="desc desc-special"
                                  ></p>
                                  {(spec?.package?.isPackage ||
                                    spec?.page?.isPage ||
                                    spec?.downloadFile?.download) && (
                                    <div className="wrap d-flex flex-wrap gap-20px">
                                      {spec?.package?.isPackage &&
                                        spec?.package?.pageURL && (
                                          <Link
                                            href={`/package/${spec?.package?.pageURL}`}
                                          >
                                            <a
                                              className="text-dark fw-600"
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              <i className="fa fa-angle-double-right mr-2"></i>
                                              {spec?.package?.packageTitle
                                                ? spec?.package?.packageTitle
                                                : "Package"}
                                            </a>
                                          </Link>
                                        )}
                                      {spec?.page?.isPage &&
                                        spec?.page?.pageUrl && (
                                          <a
                                            className="text-dark fw-600"
                                            href={spec?.page?.pageUrl}
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                            target={
                                              spec?.page?.newTab
                                                ? "_blank"
                                                : "_self"
                                            }
                                            rel="noreferrer"
                                          >
                                            <i className="fa fa-angle-double-right mr-2"></i>
                                            {spec?.page?.pageTitle
                                              ? spec?.page?.pageTitle
                                              : "Page"}
                                          </a>
                                        )}
                                      {spec?.downloadFile?.download &&
                                        spec?.downloadFile?.file && (
                                          <a
                                            className="text-dark fw-600"
                                            href={spec?.downloadFile?.file}
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <i className="fa fa-angle-double-right mr-2"></i>
                                            {spec?.downloadFile?.downloadTitle
                                              ? spec?.downloadFile
                                                  ?.downloadTitle
                                              : "Download File"}
                                          </a>
                                        )}
                                    </div>
                                  )}
                                  <div className="mt-4">
                                    <Link href={`/Special/${spec?.pageUrl}`}>
                                      <a className="btn bg-main hover-btn px-4 py-2 text-white fs-13">
                                        More Details
                                        <i className="fa fa-angle-double-right ml-2"></i>
                                      </a>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

import axiosconfig from "../../../config/axios-config";
import { useEffect, useState } from "react";

export default function Term() {
  const [terms, getTerms] = useState([]);

  useEffect(() => {
    termFn();
  }, []);
  async function termFn() {
    const res = await axiosconfig.get("admin/terms");
    const alldata = await res.data;
    getTerms(alldata.data[0]);
  }

  return (
    <>
      <div className="container">
        {terms.termsandConditions !== null ? (
          <div
            dangerouslySetInnerHTML={{ __html: terms.termsandConditions }}
          ></div>
        ) : (
          <div className="alert alert-warning text-center v-align-middle py-4">
            <i className="fa fa-exclamation-triangle mr-2"></i>
            <span>No Data To display</span>
          </div>
        )}
      </div>
    </>
  );
}
